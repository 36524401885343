$color-primary: #ffffff;
$color-secondary: goldenrod;
$color-tertiary: white;

$shadow-color: rgb(182, 182, 182);

$bkg-secondary: rgb(31, 29, 29);

$font-dark: #575757;

$title-font: 'Shrikhand', cursive;
