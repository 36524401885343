@mixin btn-gradient($color-1:$color-primary,$color-2:$color-secondary) {
    background-image: linear-gradient(0.25turn,$color-1,$color-2);
}

@mixin btn-hover--bg($color-1:$color-primary,$color-2:$color-secondary,$color-3:$shadow-color) {
    background-image: linear-gradient(lighten($color-2, 5%) , lighten($color-1, 5%));
}

@mixin input-gradient($color-1:$color-secondary,$color-2:$color-tertiary) {
    background-image: linear-gradient(0.25turn,lighten($color-1, 50%),$color-2);
}

@mixin drop-shadow($color:$shadow-color) {
    box-shadow: 0px 5px 10px $color;
}

@mixin btn-favorite-gradient($color-1:$color-primary,$color-2:$color-secondary) {
    background: -webkit-linear-gradient($color-1, $color-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


@mixin tablet-only{
    @media only screen and (min-width:map-get($breakpoint,tablette)){
        @content;
    }
}

@mixin desktop-only{
    @media only screen and (min-width:map-get($breakpoint,desktop)){
        @content;
    }
}

@mixin big-desktop-only{
    @media only screen and (min-width:map-get($breakpoint,big-desktop)){
        @content;
    }
}

@mixin ultra-wide-only{
    @media only screen and (min-width:map-get($breakpoint,ultra-wide)){
        @content;
    }
}