.keune{
    @include desktop-only{
    }
}

.keune__panel{
    background-image: url("/src/images/keune.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 85%;
    margin: 50px auto;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop-only{
        width: 750px;
        height: 500px;
    }
}

.keune__logo{
    width: 65%;
    transform: translateY(-10px);

    @include desktop-only{
        width: 350px;
    }
}

.keune__text{
    width: 85%;
    margin: 0 auto 60px auto;
    text-align: center;

    @include desktop-only{
        width: 750px;
        font-size: 1.2rem;
        margin: 80px auto 120px auto;
    }
}