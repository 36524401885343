/*-----------loader----------*/
@keyframes loader-animation{
    0%{
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}

@keyframes loader-bg{
    0%{
        opacity: 1;
        z-index: 5;
    }

    90%{
        opacity: 1;
    }

    100%{
        z-index: 5;
    }
}

/*-------favorite btn-----*/
@keyframes favorite-active{
    0%{
        opacity: 0;
    }
    
    100%{
        opacity: 1;
    }
}

@keyframes favorite-inactive{
    0%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}


/*-------meal card-------*/
@keyframes meal-card{
    0%{
        transform: translateY(20px);
        opacity: 0;
    }

    100%{
        transform: translateY(0px);
        opacity: 1;
    }
}