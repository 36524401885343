* { 
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
}

body{
    margin: 0;
    background-color: rgb(60, 56, 56);
    color: $color-primary;
}

